* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

.scale-200 {
  transform: scale(2);
}

#appContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btnOne {
  background: none;
  color: white;
  border: 1px solid white;
  font-size: 18px;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 32px;
  transition: 0.2s ease-in-out;
}

.btnOne:hover {
  background-color: white;
  color: #db287b;
  cursor: pointer;
}

.header-container {
  background-color: #db287b;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.btnTwo {
  background: none;
  color: #db287b;
  border: 1px solid #db287b;
  font-size: 18px;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 32px;
  transition: 0.2s ease-in-out;
}

.btnTwo:hover {
  background-color: #db287b;
  color: #fff;
  cursor: pointer;
}

.deactivated-btnTwo {
  background: #e6e6e6;
  color: #8f8f8f;
  border: 1px solid #8f8f8f;
  font-size: 18px;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 32px;
  transition: 0.2s ease-in-out;
}

.loading-btnTwo {
  background: #e6e6e6;
  color: #8f8f8f;
  border: 1px solid #8f8f8f;
  font-size: 18px;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.btnTwo-loadingwheel {
  width: 24px;
  height: 24px;
  border: 2px solid #8f8f8f;
  border-top: 2px solid #4e4e4e;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

.header-email {
  color: white;
  font-size: 20px;
  margin-left: 10px;
  user-select: none;
}

.header-email-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-left-dropdown {
  position: relative;
  display: inline-block;
}

.header-dropdown-content {
  position: absolute;
  display: none;
  width: 100%;
}

.header-dropdown-box {
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid #db287b;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.header-dropdown-section:hover {
  background-color: #ddd;
}

.header-dropdown-box p {
  margin-left: 5px;
}

.header-left-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-section {
  padding: 8px;
  display: flex;
  user-select: none;
}

#screenoverlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#screenoverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.4);
  cursor: pointer;
  z-index: 5;
}

.screenoverlay-content {
  z-index: 5;
}

.verifyEmailContainer {
  padding: 12px;
  border: 1px solid #000;
  width: 35vw;
  border-radius: 10px;
  color: #444444;
}

.footer-container {
  background-color: #191a44;
  padding: 8px;
  color: #fff;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #c7c7c7;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #db287b;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  opacity: 0.5;
}


@keyframes loading {
  from {transform: rotate(0turn)}
  to {transform: rotate(1turn);}
}


@media (max-width: 750px) {
  .header-container {
    flex-direction: column;
  }
  #header-left-container {
    margin-top: 16px;
  }
  #header-svg {
    transform: scale(0.7);
  }
}

@media (max-width: 1500px) {
  .verifyEmailContainer {
    width: 45vw;
  }
}

@media (max-width: 1300px) {
  .verifyEmailContainer {
    width: 55vw;
  }
}

@media (max-width: 1000px) {
  .verifyEmailContainer {
    width: 65vw;
  }
}

@media (max-width: 750px) {
  .verifyEmailContainer {
    width: 75vw;
  }
}

@media (max-width: 600px) {
  .verifyEmailContainer {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .verifyEmailContainer {
    width: 95vw;
  }
}

@media (max-width: 580px) {
  .add-list-btn {
    font-size: 12px;
    text-align: center;
    width: 128px;
  }
}

@media (max-width: 535px) {
  .acc-add-list-btn {
    display: none;
  }

  .small-add-list-btn {
    display: inherit;
    margin-top: 8px;
  }
}
