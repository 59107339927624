.homepage-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
  padding-top: 16px;
}

.homepage-menu-container {
  border-bottom: 1px solid black;
  display: flex;
}

.homepage-menubtn-container {
  flex: 1;
  display: flex;
}

.homepage-menubtn {
  background-color: #fff;
  border: none;
  min-width: 10vw;
  padding: 8px;
  padding-bottom: 11px;
  font-size: 16px;
  cursor: pointer;
}

.homepage-menubtn:hover {
  color: #414141;
}

.homepage-menu-selected:hover {
  color: #000 !important;
}

.homepage-menu-selected {
  border-bottom: 3px solid black;
  font-weight: bold;
  padding-bottom: 8px !important;
}

.homepage-search-container {
  display: flex;
  margin-top: 16px;
  background-color: #6a7188;
  padding: 16px;
  border-radius: 10px;
  padding-left: 0px;
}

.homepage-search-input {
  flex: 1;
  background: none;
  border: none;
  outline: none;
  padding-left: 8px;
  color: #fff;
  font-size: 16px;
}

.homepage-search-input::placeholder {
  color: #fff;
}

.homepage-content-container {
  flex: 1;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: center;
}

.hp-content-scroll {
  flex: 1;
  position: relative;
}

.homepage-addbtn {
  width: 300px;
  height: 150px;
  background-color: #dfdfdf;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homepage-addbtn-circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 3px solid #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-addbtn-icon {
  transform: scale(2.5);
  color: #bdbdbd;
}

.homepage-addbtn:hover {
  background-color: #eeeeee;
}

.homepage-addbtn:hover .homepage-addbtn-circle {
  border-color: #cacaca;
}

.homepage-addbtn:hover .homepage-addbtn-icon {
  color: #cacaca;
}

.homepage-accbtn {
  position: relative;
  width: 300px;
  height: 150px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 5px 5px 15px -3px #000000;
  box-shadow: 5px 5px 15px -3px #000000;
}

.homepage-accbtn-logo {
  flex: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.homepage-accbtn-namectnr {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101847;
  padding: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.homepage-accbtn-namectnr p {
  color: #fff;
}

.homepage-accbtn-logocntr {
  flex: 5;
  display: flex;
  background-color: #fff;
  padding: 10px;
  border-radius: 30px;
}

.homepage-accbtn-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 20px;
  opacity: 0;
  z-index: 2;
}

.homepage-accbtn:hover .homepage-accbtn-overlay {
  opacity: 0.25;
}

.viewAccountField {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.viewAccountField-name {
  padding: 8px;
  padding-bottom: 2px;
  font-size: 20px;
  color: #777;
}

.viewAccountField-valueContainer {
  padding: 8px;
  border-radius: 10px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewAccountField-valueContainer p {
  color: #777;
  flex: 1;
  margin-left: 8px;
}

.viewAccountField-edit-input {
  flex: 1;
  background-color: #e0e0e0;
  border: none;
  outline: none;
  margin-left: 8px;
}

.viewAccountFooterBox {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.viewAccountFooterContainer {
  display: flex;
  flex-direction: row;
}

.viewAccount-deletebtn {
  display: flex;
  border-radius: 10px;
  padding: 8px;
  background-color: #000;
  color: #fff;
  user-select: none;
  cursor: pointer;
}

.viewAccount-deletebtn:hover {
  opacity: 0.75;
}

.homepage-accbtn-iconcontainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-password-continue-btn {
  background-color: #45a175;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  border: none;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.overlay-password-continue-btn:hover {
  background-color: #47755f;
}

.changeViewBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 28px;
  /* background-color: #ddd; */
  border-radius: 8px;
}

.changeViewBtn > svg {
  color: #000;
  transition: 0.15s ease;
}

.changeViewBtn:hover > svg {
  color: #888
}

.encrypted-list-button {
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d6e4f58c;
  border-radius: 10px;
  overflow: hidden;  
  border: 1px solid rgb(153, 177, 194)
}

.encrypted-list-button-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
}

.encrypted-list-button:hover > .encrypted-list-button-overlay {
  opacity: 0.25;
}

.add-list-btn, .small-add-list-btn, .acc-add-list-btn {
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 16px;
  background-color: #555;
  border-radius: 8px;
  transition: 0.1s ease;
  cursor: pointer;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.add-list-btn:hover, .small-add-list-btn:hover, .acc-add-list-btn:hover {
  background-color: #999;
}

.small-add-list-btn {
  display: none;
}

.homePageGlobalNoteCountContainer {
  position: fixed;
  right: 40px;
  bottom: 40px;
  background-color: #fff;
  z-index: 5;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
}

@media (max-width: 1350px) {
  .homepage-content-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1050px) {
  .homepage-content-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 750px) {
  .homepage-content-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .homepage-accbtn {
    width: 350px;
    height: 200px;
  }

  .homepage-addbtn {
    width: 350px;
    height: 200px;
  }

  .homepage-search-container {
    margin-bottom: 16px;
  }
}

@media (max-width: 450px) {
  .homepage-accbtn {
    width: 300px;
    height: 150px;
  }

  .homepage-addbtn {
    width: 300px;
    height: 150px;
  }
}
