.fgp-email-input {
  outline: none;
  padding: 12px;
  border: 1px solid #888;
  border-radius: 8px;
  background-color: #fff;
}

.fgp-email-input:focus {
  border: 1px solid #000;
}

@media (max-width: 650px) {
  .fgp-inp-container {
    width: 120%;
  }
}

@media (max-width: 460px) {
  .fgp-inp-container {
    width: 95%;
  }
}
