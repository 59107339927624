.overlay-container {
  width: 35vw;
  min-height: 70vh;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.overlay-header-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #949494;
}

.overlay-header-title {
  color: #949494;
  font-size: 24px;
  text-align: center;
  padding-bottom: 24px;
}

.overlay-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.addAcc-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.addAcc-input-container {
  /* Remove margin top after */
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
  align-items: center;
}

.addAcc-input-container p {
  padding: 10px;
  text-align: end;
  user-select: none;
}

.addAcc-input {
  flex: 5;
  border: none;
  outline: none;
  margin-right: 10px;
  min-width: 5px;
  font-size: 16px;
}

.addIdInput-topContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.addIdInput-topContainer p {
  font-size: 16px;
}

.addIdInput-topContainer input {
  outline: none;
  border: 1px solid black;
  padding: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 10px;
}

.addAcc-helpOuline {
  margin-left: 10px;
  cursor: pointer;
}

.addAcc-help-container {
  position: absolute;
  right: 102%;
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  display: none;
}

.addAcc-helpOutline-container:hover + .addAcc-help-container {
  display: block;
}

.addAcc-help-container p {
  text-align: start;
}

.addAcc-footer-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.confirm-delete-account-overlay-container {
  width: 35vw;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.confirm-delete-account-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.confirm-delete-acc-btn {
  min-width: 100px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.confirm-delete-acc-btn-yes {
  background-color: #45a175;
  color: #fff;
}

.confirm-delete-acc-btn-yes:hover {
  background-color: #47755f;
}

.confirm-delete-acc-btn-no {
  background-color: #a14545;
  color: #fff;
}

.confirm-delete-acc-btn-no:hover {
  background-color: #754747;
}

.addIdOverlay-box {
  position: relative;
  flex: 1;
  margin-bottom: 10px;
  margin-top: 10px;
}

.addIdOverlay-container {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.addIdOverlay-footerContainer {
  display: flex;
  justify-content: flex-end;
}

.addIdInput-container {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border: 1px solid black;
  border-radius: 10px;
  flex: 1;
}

.addIdInput-container input {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.addIdInput-container p {
  padding-right: 12px;
}

.addIdFlex {
  display: flex;
  flex-direction: row;
}

.addIdOverlay-seperator {
  padding: 8px;
}

.addIdCountryInput-dropdown-container {
  position: absolute;
  min-width: 100%;
  left: 0%;
  top: 100%;
  z-index: 10;
  height: 200px;
  display: none;
}

.addIdCountry-dropdown-box {
  flex: 1;
  overflow-y: scroll;
  padding: 8px;
  background-color: #fff;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

.addIdCountryInput:focus + .addIdCountryInput-dropdown-container {
  display: flex;
}

.addIdCountry-dropdown-country {
  border-bottom: 1px solid black;
  padding: 8px;
  cursor: pointer;
}

.addIdCountry-dropdown-country:hover {
  background-color: #dadada;
}

.addIdCountryInput-dropdown-container:hover {
  display: flex;
}

.card-input-container {
  border: 1px solid black;
  border-radius: 10px;
  padding: 12px;
  display: flex;
}

.card-input-container input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
}

.card-input-container p {
  padding-right: 12px;
  font-size: 16px;
  color: #555;
}

.card-input-element-box {
  flex: 1;
  position: relative;
}

.card-input-element-container {
  position: relative;
  flex: 1;
}

.card-input-top-container {
  border: 1px solid black;
  border-radius: 10px;
  padding: 12px;
  position: relative;
}

.card-input-top-container input {
  border: none;
  outline: none;
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
}

.add-note-overlay-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.note-overlay-textarea {
  flex: 1;
  resize: none;
  outline: none;
  border: none;
  font-size: 16px;
}

.note-overlay-textarea-container {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
}

.add-note-overlay-footer {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1500px) {
  .overlay-container {
    width: 45vw;
  }

  .confirm-delete-account-overlay-container {
    width: 45vw;
  }
}

@media (max-width: 1300px) {
  .overlay-container {
    width: 55vw;
  }

  .confirm-delete-account-overlay-container {
    width: 55vw;
  }

  .addAcc-help-container {
    position: absolute;
    right: 0%;
    left: 0%;
    width: 50%;
    bottom: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: none;
    z-index: 10;
    border: 1px solid black;
  }
}

@media (max-width: 1000px) {
  .overlay-container {
    width: 65vw;
  }

  .confirm-delete-account-overlay-container {
    width: 65vw;
  }
}

@media (max-width: 750px) {
  .overlay-container {
    width: 75vw;
  }

  .confirm-delete-account-overlay-container {
    width: 75vw;
  }
}

@media (max-width: 600px) {
  .overlay-container {
    width: 90vw;
  }

  .confirm-delete-account-overlay-container {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .overlay-container {
    width: 95vw;
  }

  .confirm-delete-account-overlay-container {
    width: 95vw;
  }
}
