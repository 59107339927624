.subscription-box {
  border: 1px solid #db287b;
  padding: 50px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 25vw;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-box-currency {
  margin-left: 20px;
  font-size: 24px;
  color: #db287b;
  font-weight: bold;
}

.sub-box-price {
  font-size: 48px;
  color: #db287b;
  font-weight: bold;
}

.sub-box-permonth {
  color: #db287b;
  font-size: 24px;
  font-weight: bold;
}

.sub-box-desc {
  color: #db287b;
  margin-top: 30px;
  text-align: center;
  width: 80%;
}

@media (max-width: 1160px) {
  .subscription-box {
    width: 40vw;
  }
}

@media (max-width: 825px) {
  .subscription-box {
    width: 55vw;
  }
}

@media (max-width: 535px) {
  .subscription-box {
    width: 70vw;
  }
}

@media (max-width: 380px) {
  .subscription-box {
    width: 90vw;
  }
}
