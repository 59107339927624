.settings-page-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.settings-page-sidebar-container {
  flex: 1;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
}

.settings-page-main-container {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.settings-sidebar-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid black;
  user-select: none;
}

.settings-sidebar-item-container {
  padding: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 12px;
  margin-right: 12px;
  user-select: none;
  cursor: pointer;
}

.settings-page-content-container {
  border: 1px solid black;
  border-radius: 20px;
  width: 40%;
  height: 75%;
  display: flex;
  flex-direction: column;
}

.settings-sidebar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 4;
  background-color: #fff;
  height: 100%;
  border-right: 1px solid black;
}

.settings-page-menu-button-container {
  display: none;
  position: absolute;
  left: 100%;
  margin-left: 8px;
  margin-top: 8px;
  cursor: pointer;
}

.settings-page-menu-button-container div {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #101847;
  border-radius: 50%;
}

.settings-sidebar-closebtn {
  display: none;
  margin: 20px;
  padding: 8px;
  background-color: #eb5252;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.settings-sidebar-closebtn:hover {
  background-color: #a14545;
}

.settings-sidebar-logoutbtn {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-top: 1px solid black;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  background-color: #fff;
}

.settings-sidebar-logoutbtn:hover {
  background-color: #ddd;
}

.settings-page-content-header {
  padding: 24px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-page-content-header p {
  font-size: 24px;
  user-select: none;
}

.settings-page-individual-container {
  flex: 1;
  padding: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.settings-page-footer-container {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
}

.settings-footer-input-box-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.settings-footer-input-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: 1px solid black;
  margin-right: 16px;
  padding: 8px;
}

.settings-footer-input-container input {
  flex: 1;
  outline: none;
  border: none;
  padding-right: 8px;
}

.settings-account-emailinput {
  padding: 8px;
  padding-left: 0px;
  margin-left: 8px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 2px;
}

.settings-account-emailinput:focus {
  border-bottom: 3px solid black;
  margin-bottom: 0px;
}

.settings-account-password-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  padding: 8px;
  border-radius: 10px;
}

.settings-account-password-input-container input {
  outline: none;
  border: none;
  padding-right: 8px;
}

.settings-checkbox {
  border: 1px solid black;
  margin-left: 8px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.settings-pwgen-othercharsinput {
  outline: none;
  padding: 8px;
  padding-left: 4px;
  border-radius: 5px;
  margin-left: 4px;
  border: 1px solid black;
}

.settings-pwgen-pwleninput {
  outline: none;
  border: 1px solid black;
  padding: 4px;
  border-radius: 5px;
  width: 20%;
}

.password-gen-slider {
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  height: 16px;
  background: none;
  /* background-color: #ddd; */
  border-radius: 20px;
  transition: 0.1s ease-in-out;
  z-index: 3;
}

.password-gen-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid black;
}

@media (max-width: 1500px) {
  .settings-page-main-container {
    flex: 4;
  }
  .settings-page-content-container {
    width: 50%;
  }
}

@media (max-width: 1350px) {
  .settings-page-content-container {
    width: 55%;
  }
}

@media (max-width: 1200px) {
  .settings-page-main-container {
    flex: 3;
  }
  .settings-page-content-container {
    width: 65%;
  }
}

@media (max-width: 1000px) {
  .settings-page-content-container {
    width: 75%;
  }
}

@media (max-width: 890px) {
  .settings-page-content-container {
    width: 85%;
  }
}

@media (max-width: 780px) {
  .settings-page-sidebar-container {
    flex: 0;
    border: none;
  }

  .settings-page-menu-button-container {
    display: flex;
  }

  .settings-sidebar-wrapper {
    position: absolute;
    display: none;
  }

  .settings-sidebar-closebtn {
    display: flex;
  }
}

@media (max-width: 650px) {
  .settings-page-content-container {
    width: 85%;
  }
}

@media (max-width: 540px) {
  .settings-page-content-container {
    width: 95%;
  }
}

@media (max-width: 515px) {
  .settings-page-footer-container {
    flex-direction: column;
  }
  .settings-footer-input-box-container {
    margin-bottom: 10px;
  }
  .settings-page-content-container {
    height: 95%;
  }
}

@media (max-height: 750px) {
  .settings-page-content-container {
    height: 85%;
  }
}
